"use client";

import React from "react";
import FormContactUs from "./FormContactUs"; // Ensure you have this component

const CallToBoost = ({
  title = "Do You Want a Website?",
  description = "Get a high-performing, beautifully designed website tailored to your business needs. Our custom web design services will help you attract more customers and grow your online presence. Share your details with us, and we’ll gladly reach out to provide you with a free audit, giving you valuable insights into your website’s current performance.",
  contactDetails = {
    phone: "+91 98212 12676",
    email: "sales1@prateeksha.com",
  },
}) => {
  return (
    <div>
      <section className="bg-colorAqua-700 py-12 md:py-20 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto grid grid-cols-1 lg:grid-cols-2 gap-10 items-center">
          {/* Left Section: Title, Description, and Contact Details */}
          <div className="text-white">
            <h2 className="text-white text-3xl md:text-6xl font-bold mb-6">{title}</h2>
            <p className="text-xl mb-8">{description}</p>
            <div className="text-base">
              <p className="mb-2 text-xl">
                <strong>Call us:</strong> <span className="text-yellow-300">{contactDetails.phone}</span>
              </p>
              <p className="text-xl">
                <strong>Email us:</strong>{" "}
                <a
                  href={`mailto:${contactDetails.email}`}
                  className="text-yellow-300 underline hover:text-yellow-400"
                >
                  {contactDetails.email}
                </a>
              </p>
            </div>
          </div>

          {/* Right Section: Contact Form */}
          <div className="bg-white rounded-lg shadow-lg p-8">
             <div>Kindly fill in the form and we will get back to you</div>
            <FormContactUs />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CallToBoost;
