"use client";
import Image from "next/image";
import Link from "next/link";
import { AiFillPhone } from "react-icons/ai";
import { AiFillCheckCircle } from "react-icons/ai";

const fallbackImages = [
  "affordable-web-design.jpg",
  "creative-web-design.jpg",
  "digital-marketing-services.jpg",
  "ecommerce-web-design-agency.jpg",
  "ecommerce-web-design-company.jpg",
  "ecommerce-web-design-services.jpg",
  "reactjs-development-services.jpg",
  "responsive-web-design.jpg",
  "shopify-expert-services.jpg",
  "small-business-web-design.jpg",
  "web-design-agency-near-me.jpg",
  "web-design-and-development-services.jpg",
  "web-design-packages.jpg",
  "web-design-services.jpg",
  "web-page-designers-for-small-business.jpg",
  "website-design-services.jpg",
];

const HeroSection = ({
  image = "web-design-agency-near-me.jpg",
  abovetitle = "North Carolina and Mumbai",
  title = "Let's design a fantastic website through our Web Design Services",
  colouredTitle = "",
  salespitch = "Unlock your company's digital potential with our experienced web design services in North Carolina and Mumbai, where we blend creativity and technology to create engaging, result-driven websites",
  salesPoints = [
    "Premier Website Design Services",
    "With over 20 years of expertise in web design and development",
    "Enhance your online presence with a high-quality, conversion-focused website.",
  ],
  letsGoLink = "/contact-us",
  letsGoText = "Book your FREE call",
}) => {
  // Check if the provided image exists in the fallback images array
  const resolvedImage = fallbackImages.includes(image)
    ? `/images/hero/${image}`
    : `/images/hero/${fallbackImages[Math.floor(Math.random() * fallbackImages.length)]}`;

  return (
    <>
      <section className="max-w-screen-2xl mx-auto w-full">
        <div className="flex flex-col md:flex-row gap-3 md:gap-6 items-center justify-center md:justify-between">
          <div className="flex-1 flex-shrink-0 order-2 md:order-1 pr-5 md:pr-0 mb-10 mx-5">
            <div className="-z-10">
              <h1 className="font-bold text-neutral-800 py-2">
                {title}
                <span className="text-colorOrange-700"> {colouredTitle}</span>
              </h1>
              <p
                dangerouslySetInnerHTML={{ __html: salespitch }}
                className="text-xl font-semibold pb-2"
              ></p>
              <ul className="mt-4 space-y-2">
                {salesPoints.map((point, index) => (
                  <li
                    key={index}
                    className="flex items-start text-base justify-start"
                  >
                    <div>
                      <AiFillCheckCircle className="w-5 h-5 text-neutral-600 mr-2 mt-1" />
                    </div>
                    {point}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-0 md:mt-6">
              <Link
                href={letsGoLink}
                className="inline-block font-medium md:text-base mt-4 md:mt-4 lg:mt-0 lg:text-lg text-colorOrange-400 hover:text-white hover:bg-yellow-700 rounded-full px-10 py-3 md:px-15 bg-neutral-600 transition-colors duration-300 ease-in-out"
              >
                {letsGoText}
              </Link>
            </div>
            <div className="text-base md:text-lg py-4 flex items-center">
              <AiFillPhone className="w-5 h-5 mr-2 text-neutral-600 text-lg md:text-2xl" />
              Call Our Experts now +91-98212 12676 (India)
            </div>
          </div>
          <div className="flex-1 self-end order-1 md:order-2 px-0 mx-0 pt-4 md:py-10 -z-10">
            <Image
              src={resolvedImage}
              className="self-center md:self-end w-full  md:max-w-[40rem]"
              alt={`${title} ${colouredTitle}`}
              priority={true}
              width={700}
              height={770}
              quality={75}
              sizes="(max-width: 600px) 100vw, (min-width: 600px) 50vw"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
